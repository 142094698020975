import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeadersWhite3 from '../components/headers-white/IndexSectionHeadersWhite3';
import IndexSectionFeaturesWhitePattern6 from '../components/features-white-pattern/IndexSectionFeaturesWhitePattern6';
import IndexSectionLogoCloudsWhitePattern7 from '../components/logo-clouds-white-pattern/IndexSectionLogoCloudsWhitePattern7';
import IndexSectionContactsWhitePattern5 from '../components/contacts-white-pattern/IndexSectionContactsWhitePattern5';
import IndexSectionFootersWhitePattern4 from '../components/footers-white-pattern/IndexSectionFootersWhitePattern4';
import IndexSectionFootersWhitePattern1 from '../components/footers-white-pattern/IndexSectionFootersWhitePattern1';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeadersWhite3 />
      <IndexSectionFeaturesWhitePattern6 />
      <IndexSectionLogoCloudsWhitePattern7 />
      <IndexSectionContactsWhitePattern5 />
      <IndexSectionFootersWhitePattern4 />
      <IndexSectionFootersWhitePattern1 />
    </React.Fragment>
  );
}

