import React from "react";
import partner1 from "../../assets/images/partner1.jpg";
import partner2 from "../../assets/images/partner2.jpg";
import partner3 from "../../assets/images/partner3.jpg";

//slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function IndexSectionLogoCloudsWhitePattern7() {
  /* var settings = {
    dots: true,
    infinite: true,

    slidesToShow: 5,

    initialSlide: 5,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
     responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ] 
  }; */

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    rtl: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      /* {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }, */
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <>
        <section
          id="partners"
          className="py-20 xl:pt-24 bg-white"
          style={{
            backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
            backgroundPosition: "center",
          }}
        >
          <div className="container px-4 mx-auto">
            <div className="mb-8 text-center">
              <span className="inline-block py-px px-2 mb-4 text-xl leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-9xl">
                الشركاء{" "}
              </span>
              <h3 className="mb-4 text-4xl md:text-5xl text-coolGray-900 font-bold tracking-tighter">
                كن شريكًا معنا واستفد من أفضل البرامج التعليمية المتاحة لطلابك،
                لتعزيز مهاراتهم وتحقيق تميزهم
              </h3>
            </div>
            <div className="slider-container">
              <Slider {...settings}>
                <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/logo-wide.jpg"
                      alt
                    />
                  </div>
                </div>
                <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/images-1.jfif"
                      alt
                    />
                  </div>
                </div>
                <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/images-2-jfif-1723010488249.webp"
                      alt
                    />
                  </div>
                </div>
                <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 md:mb-0">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/cropped-1393042-10202435560437411-812328677-n.webp"
                      alt
                    />
                  </div>
                </div>
                <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/images-3.jfif"
                      alt
                    />
                  </div>
                </div>
                <div className="w-1/2 md:w-1/2 lg:w-1/2 px-4 mb-8 lg:mb-0">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src={partner1}
                      alt
                    />
                  </div>
                </div>

                <div className="w-1/2 md:w-1/2 lg:w-1/2 px-4 mb-8 lg:mb-0">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src={partner2}
                      alt
                    />
                  </div>
                </div>

                <div className="w-1/2 md:w-1/2 lg:w-1/2 px-4 mb-8 lg:mb-0">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src={partner3}
                      alt
                    />
                  </div>
                </div>
              </Slider>
            </div>
            {/*   <div className="flex flex-wrap justify-center -mx-4 ">
              <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0">
                <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                  <img
                    className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                    style={{ maxHeight: "90%" }}
                    src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/logo-wide.jpg"
                    alt
                  />
                </div>
              </div>

              <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0">
                <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                  <img
                    className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                    style={{ maxHeight: "90%" }}
                    src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/images-1.jfif"
                    alt
                  />
                </div>
              </div>
              <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 lg:mb-0">
                <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                  <img
                    className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                    style={{ maxHeight: "90%" }}
                    src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/images-2-jfif-1723010488249.webp"
                    alt
                  />
                </div>
              </div>
              <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-8 md:mb-0">
                <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                  <img
                    className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                    style={{ maxHeight: "90%" }}
                    src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/cropped-1393042-10202435560437411-812328677-n.webp"
                    alt
                  />
                </div>
              </div>
              <div className="w-1/2 md:w-1/3 lg:w-1/5 px-4">
                <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                  <img
                    className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                    style={{ maxHeight: "90%" }}
                    src="https://static.shuffle.dev/uploads/files/6a/6a4813e4e9a31855566b397eabda8d3caf1be026/images-3.jfif"
                    alt
                  />
                </div>
              </div>

              <div className="flex flex-wrap justify-center -mx-4 m-7">
                <div className="w-1/2 md:w-1/2 lg:w-1/2 px-4 mb-8 lg:mb-0">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src={partner1}
                      alt
                    />
                  </div>
                </div>

                <div className="w-1/2 md:w-1/2 lg:w-1/2 px-4 mb-8 lg:mb-0">
                  <div className="flex items-center h-32 md:h-36 px-4 md:px-8 rounded-md bg-coolGray-50 shadow-md">
                    <img
                      className="mx-auto max-h-full max-w-fit object-contain rounded-lg"
                      style={{ maxHeight: "90%" }}
                      src={partner2}
                      alt
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
